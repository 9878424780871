<i18n>
{
  "en": {
    "log-out": "Log out"
  },
  "ja": {
    "log-out": "ログアウト"
  }
}
</i18n>

<template>
  <v-menu v-if="isAuthenticated" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" text>
        <v-icon v-if="$vuetify.breakpoint.xs">mdi-account</v-icon>
        <span v-else>{{ group }}</span>
        <v-icon>mdi-menu-down</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-subheader>{{ identity }}</v-subheader>
      <v-list-item-group>
        <v-list-item @click="logout">
          <v-list-item-title>
            <v-icon left>mdi-logout-variant</v-icon>
            {{ $t('log-out') }}
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'LoggedInWidget',
  computed: {
    group() {
      const parts = this.identity.split('@', 2);
      return parts[parts.length - 1];
    },
    identity() {
      return this.$api.identity;
    },
    isAuthenticated() {
      return this.$api.isAuthenticated;
    }
  },
  methods: {
    async logout() {
      this.$api.unsetToken();
      await this.$router.push('/');
    }
  }
}
</script>
